const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/Base');
const Home = () => import(/* webpackChunkName: "home" */'../views/Home');
const Work = () => import(/* webpackChunkName: "home" */'../views/Work');
const Bigdata = () => import(/* webpackChunkName: "home" */'../views/Big-Data-Analysis');
const BusinessIntelligenceTools = () => import(/* webpackChunkName: "home" */'../views/Business-Intelligence-Tools');
const ModernWebDevelopment = () => import(/* webpackChunkName: "home" */'../views/Modern-Web-Development');
const MobileAppDevelopment = () => import(/* webpackChunkName: "home" */'../views/Mobile-App-Development');
const About = () => import(/* webpackChunkName: "home" */'../views/About');
const Contact = () => import(/* webpackChunkName: "home" */'../views/Contact');

export default {
    path: '/',
    name: 'DashboardLayout',
    component: Layout,
    children: [
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/work/',
            name: 'Work',
            component: Work
        },
        {
            path: '/bigdata/',
            name: 'Bigdata',
            component: Bigdata
        },
        {
            path: '/business-intelligence-tools/',
            name: 'BusinessIntelligenceTools',
            component: BusinessIntelligenceTools
        },
        {
            path: '/modern-web-development/',
            name: 'ModernWebDevelopment',
            component: ModernWebDevelopment
        },
        {
            path: '/mobile-app-development/',
            name: 'MobileAppDevelopment',
            component: MobileAppDevelopment
        },
        {
            path: '/about/',
            name: 'About',
            component: About
        },
        {
            path: '/contact/',
            name: 'Contact',
            component: Contact
        }
    ]
};
